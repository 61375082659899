import * as React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, Grid, Cell, CellRow, FlavorBrowser, InstagramBanner, QuotesBrowser } from '@components'
import { palette } from '@theme'


interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}


const IndexPage = ({ data: { sanityPage: {
  title,
  gridItems,
  quotes
} } }: PageProps) => {
  return (
  <Layout backgroundColor={palette.lime} >
    <Helmet>
      <title>Bawi Agua Fresca: {title}</title>
    </Helmet>
    <Grid>
      {gridItems?.map(item => {
        if(!item) return
        if(item._type === 'cell' ) return (
          <Cell key={item._key} {...item as GatsbyTypes.SanityCell} />
        )
        if( item._type === 'cellRow' ) return (
          <CellRow key={item._key} {...item as GatsbyTypes.SanityCellRow} />
        )
      })}
      <FlavorBrowser />
      {!!quotes && quotes.length > 0 && (
        <QuotesBrowser {...{ quotes }} />
      )}
      <InstagramBanner />
    </Grid>
  </Layout>
)}


export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: {current: {eq: "home"}}) {
      ...pageFields
    }
  }
`


export default IndexPage
